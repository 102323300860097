<template>
    <div class="tgcss-max-w-[1200px] tgcss-m-auto tgcss-w-full tgcss-relative">

        <div class="tgcss-font-semibold tgcss-text-lg tgcss-flex-grow tgcss-mb-3">قیمت ارزهای دیجیتال | بازارها</div>

        <div class="tgcss-flex tgcss-w-full tgcss-h-12 tgcss-border-b tgcss-border-gray-200">
            <div class="tgcss-flex tgcss-items-center">
                <div class="tgcss-flex tgcss-cursor-pointer tgcss-items-center tgcss-border-b-4 tgcss-border-gray-600 tgcss-h-12 -tgcss-mb-[3px] tgcss-px-4 tgcss-font-semibold tgcss-text-sm">
                    بازارهای معاملاتی
                </div>
                <div class="tgcss-flex tgcss-cursor-pointer tgcss-opacity-60 tgcss-items-center tgcss-h-12 -tgcss-mb-[3px] tgcss-px-4 tgcss-font-semibold tgcss-text-sm">
                    خرید و فروش آنی
                </div>
            </div>
            <div class="tgcss-flex-grow"></div>
        </div>

        <div class="tgcss-grid tgcss-grid-cols-4 tgcss-gap-3 tgcss-py-4">
            <div class="card" v-for="(item, index) in topItems" v-bind:key="index"> 
                <div class="card-body tgcss-cursor-pointer !tgcss-py-3"> 

                    <div class="tgcss-flex tgcss-items-start tgcss-pb-4">
                        <div class="tgcss-flex tgcss-cursor-pointer tgcss-items-center tgcss-font-semibold tgcss-text-base tgcss-flex-grow">
                        {{ item.tag }}
                        </div>
                        <div class="">
                            <div class="tgcss-flex tgcss-gap-2 tgcss-items-center">
                                <div class="grow !tgcss-no-underline tgcss-text-left">
                                    <h3 class="!tgcss-font-bold !tgcss-m-0 !tgcss-text-sm tgcss-flex tgcss-items-center !tgcss-text-gray-700 tgcss-text-left tgcss-justify-end">
                                        {{ item.nameFa }}
                                    </h3>
                                    <div class="tgcss-text-gray-500 tgcss-font-medium tgcss-text-xss tgcss-mb-1 tgcss-text-left">
                                        {{ item.symbol }}
                                    </div>
                                </div>
                                
                                <div class="tgcss-bg-green-100 tgcss-rounded-full tgcss-size-7 tgcss-mr-1 tgcss-shrink-0">
                                    <img :src="'https://api.wallex.ir/coins/'+item.symbol+'/icon/svg'" alt="" class="tgcss-h-7 tgcss-rounded-full">
                                </div>
                            </div>
                        </div> 
                    </div>
                    
                    <div class="tgcss-flex tgcss-items-center">
                        <div class="tgcss-flex tgcss-cursor-pointer tgcss-items-center tgcss-font-semibold tgcss-text-sm tgcss-flex-grow">
                            <div class="tgcss-inline-flex tgcss-items-center  tgcss-font-semibold text-right" :class="item.changeLevel == 'high' ? 'tgcss-text-green-500' : 'tgcss-text-red-500'"  dir="ltr">
                                        <svg class="tgcss-w-4 tgcss-mr-1" :class="item.changeLevel == 'low' ? ' tgcss-rotate-180 ' : ''"  data-slot="icon" aria-hidden="true" fill="none" stroke-width="3" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="m4.5 15.75 7.5-7.5 7.5 7.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                        </svg>
                                        {{item.change}}
                                    </div>
                        </div>
                        <div class="">
                            <h3 class="!tgcss-font-bold !tgcss-m-0 !tgcss-text-xs tgcss-flex tgcss-items-center !tgcss-text-gray-700">
                                {{ item.changep }} 
                                <span class="tgcss-hidden md:tgcss-inline-block tgcss-opacity-50 tgcss-mr-1">تتر</span>
                            </h3>
                        </div> 
                    </div>
                </div>
            </div>

        </div>

        <div class="card">
            <div class="tgcss-flex tgcss-w-full tgcss-h-12 tgcss-border-b tgcss-border-gray-200">
                <div class="tgcss-flex tgcss-items-center tgcss-px-2">
                    <div class="tgcss-flex tgcss-cursor-pointer tgcss-items-center tgcss-border-b-4 tgcss-border-gray-600 tgcss-h-12 -tgcss-mb-[3px] tgcss-px-4 tgcss-font-semibold tgcss-text-sm">
                        اسپات
                    </div>
                    <div class="tgcss-flex tgcss-cursor-pointer tgcss-opacity-60 tgcss-items-center tgcss-h-12 -tgcss-mb-[3px] tgcss-px-4 tgcss-font-semibold tgcss-text-sm">
                        تعهدی
                        <span class="tgcss-bg-red-500 tgcss-text-white tgcss-rounded-md tgcss-font-semibold tgcss-text-xss tgcss-leading-3 tgcss-pb-1 tgcss-px-1 tgcss-mr-1">جدید</span>
                    </div>
                </div>
                <div class="tgcss-flex-grow"></div>
            </div>

           <div class="card-body !tgcss-pb-0">
                <div class="tgcss-flex tgcss-items-center tgcss-mb-4">
                    <div class="tgcss-flex tgcss-font-semibold tgcss-text-lg tgcss-flex-grow">

                        <div class="tgcss-flex tgcss-relative tgcss-cursor-pointer tgcss-ml-2 tgcss-items-center tgcss-pt-1.5  tgcss-pb-2 tgcss-pr-3 tgcss-pl-3 tgcss-font-semibold tgcss-text-sm tgcss-bg-gray-100 hover:tgcss-bg-gray-200 tgcss-rounded-md">
                            <span class="tgcss-whitespace-nowrap" @click="marketBaseShow = true">
                                {{ marketBaseSelect[0]["type"] == "all" ? "پایه بازار" : marketBaseSelect[0]["name"] }}
                            </span>
                            <svg class="tgcss-w-4 tgcss-mr-3 tgcss-relative tgcss-top-[2px]" :class="marketBaseShow ? ' tgcss-rotate-180 ' : ''"  data-slot="icon" aria-hidden="true" fill="none" stroke-width="3" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="m19.5 8.25-7.5 7.5-7.5-7.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                            <div v-if="marketBaseShow"  @click="marketBaseShow = false" class="tgcss-inset-0 tgcss-fixed tgcss-bg-gray-700 tgcss-opacity-20 tgcss-z-10"></div>
                            <div v-if="marketBaseShow" class="tgcss-absolute tgcss-min-w-36 tgcss-bg-white tgcss-p-2 tgcss-pb-1 tgcss-top-10 tgcss-z-20 tgcss-right-0 tgcss-rounded-md">
                                <ul class="!tgcss-mb-0">
                                    <li v-for="(item, index) in marketBaseList" v-bind:key="index">
                                        <div  @click="selectMarketBase(item);marketBaseShow = false" :class="marketBaseSelect[0]['type'] == item.type ? ' tgcss-bg-gray-100 ' : ''"  class=" hover:tgcss-bg-gray-100 tgcss-mb-1 tgcss-px-3 tgcss-pt-1.5 tgcss-pb-2.5 tgcss-rounded-md tgcss-flex tgcss-items-center">
                                            <div class="tgcss-flex-grow tgcss-flex tgcss-items-center">{{item.name}} <div v-if='item.type != "all"' class="tgcss-opacity-50 tgcss-mr-1 tgcss-text-xs tgcss-relative tgcss-top-[1px]">({{item.type}})</div></div>
                                            <svg v-if='marketBaseSelect[0]["type"] == item.type' class="tgcss-w-4" data-slot="icon" aria-hidden="true" fill="none" stroke-width="3" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path d="m4.5 12.75 6 6 9-13.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="tgcss-flex tgcss-relative tgcss-cursor-pointer  tgcss-ml-2 tgcss-items-center tgcss-pt-1.5  tgcss-pb-2 tgcss-pr-2 tgcss-pl-3 tgcss-font-semibold tgcss-text-sm tgcss-bg-gray-100 hover:tgcss-bg-gray-200 tgcss-rounded-md">
                            <svg class="tgcss-w-5 tgcss-ml-1 tgcss-relative tgcss-top-[1px]"  data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                            <span class="tgcss-whitespace-nowrap" @click="sortShow = true">
                                {{ sortSelect[0]["type"] == "new" ? "جدیدترین‌ها" : sortSelect[0]["name"] }}
                            </span>
                            <svg class="tgcss-w-4 tgcss-mr-3 tgcss-relative tgcss-top-[2px]" :class="sortShow ? ' tgcss-rotate-180 ' : ''"  data-slot="icon" aria-hidden="true" fill="none" stroke-width="3" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="m19.5 8.25-7.5 7.5-7.5-7.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                            <div v-if="sortShow"  @click="sortShow = false" class="tgcss-inset-0 tgcss-fixed tgcss-bg-gray-700 tgcss-opacity-20 tgcss-z-10"></div>
                            <div v-if="sortShow" class="tgcss-absolute tgcss-min-w-36 tgcss-bg-white tgcss-p-2 tgcss-pb-1 tgcss-top-10 tgcss-z-20 tgcss-right-0 tgcss-rounded-md">
                                <ul class="!tgcss-mb-0">
                                    <li v-for="(item, index) in sortList" v-bind:key="index">
                                        <div  @click="selectSort(item);sortShow = false" :class="sortSelect[0]['type'] == item.type ? ' tgcss-bg-gray-100 ' : ''"  class=" hover:tgcss-bg-gray-100 tgcss-mb-1 tgcss-px-3 tgcss-pt-1.5 tgcss-pb-2.5 tgcss-rounded-md tgcss-flex tgcss-items-center">
                                            <div class="tgcss-flex-grow tgcss-flex tgcss-items-center">{{item.name}} </div>
                                            <svg v-if='sortSelect[0]["type"] == item.type' class="tgcss-w-4" data-slot="icon" aria-hidden="true" fill="none" stroke-width="3" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path d="m4.5 12.75 6 6 9-13.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>


                        <div class="tgcss-flex-grow"></div>
                    </div>

                    <label class=" tgcss-items-center tgcss-bg-gray-100 tgcss-rounded-md tgcss-p-1 tgcss-px-1 tgcss-font-semibold tgcss-cursor-pointer !tgcss-hidden md:!tgcss-flex">
                        <svg class="tgcss-w-6 tgcss-mr-1" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                        <input type="text" v-model="search" placeholder="جستجوی بازار ..." class="!tgcss-bg-transparent !tgcss-border-none !tgcss-shadow-none ">
                    </label>
                    <svg class="tgcss-w-6 tgcss-ml-2 tgcss-opacity-50 tgcss-mr-4 hover:tgcss-opacity-100 tgcss-cursor-pointer" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                </div>
                <div class="tgcss-flex tgcss-w-full tgcss-overflow-x-auto tgcss-pb-3 tgcss-pt-1">
                    <div class="tgcss-bg-gray-900 tgcss-whitespace-nowrap tgcss-text-white tgcss-rounded-full tgcss-ml-2 tgcss-px-2.5 tgcss-pb-1.5 tgcss-pt-1 tgcss-font-semibold tgcss-text-xs tgcss-cursor-pointer">همه</div>
                    <div class="tgcss-bg-gray-100 hover:tgcss-bg-gray-900 hover:tgcss-text-white tgcss-whitespace-nowrap tgcss-text-gray-900 tgcss-rounded-full tgcss-ml-2 tgcss-px-2.5 tgcss-pb-1.5 tgcss-pt-1 tgcss-font-semibold tgcss-text-xs tgcss-cursor-pointer">
                        قرارداد هوشمند
                    </div>
                    <div class="tgcss-bg-gray-100 hover:tgcss-bg-gray-900 hover:tgcss-text-white tgcss-whitespace-nowrap tgcss-text-gray-900 tgcss-rounded-full tgcss-ml-2 tgcss-px-2.5 tgcss-pb-1.5 tgcss-pt-1 tgcss-font-semibold tgcss-text-xs tgcss-cursor-pointer">
                        قرارداد هوشمند
                    </div>
                    <div class="tgcss-bg-gray-100 hover:tgcss-bg-gray-900 hover:tgcss-text-white tgcss-whitespace-nowrap tgcss-text-gray-900 tgcss-rounded-full tgcss-ml-2 tgcss-px-2.5 tgcss-pb-1.5 tgcss-pt-1 tgcss-font-semibold tgcss-text-xs tgcss-cursor-pointer">
                        میم کوین
                    </div>
                    <div class="tgcss-bg-gray-100 hover:tgcss-bg-gray-900 hover:tgcss-text-white tgcss-whitespace-nowrap tgcss-text-gray-900 tgcss-rounded-full tgcss-ml-2 tgcss-px-2.5 tgcss-pb-1.5 tgcss-pt-1 tgcss-font-semibold tgcss-text-xs tgcss-cursor-pointer">
                        دیفای
                    </div>
                    <div class="tgcss-bg-gray-100 hover:tgcss-bg-gray-900 hover:tgcss-text-white tgcss-whitespace-nowrap tgcss-text-gray-900 tgcss-rounded-full tgcss-ml-2 tgcss-px-2.5 tgcss-pb-1.5 tgcss-pt-1 tgcss-font-semibold tgcss-text-xs tgcss-cursor-pointer">
                        استیبل کوین
                    </div>
                    <div class="tgcss-bg-gray-100 hover:tgcss-bg-gray-900 hover:tgcss-text-white tgcss-whitespace-nowrap tgcss-text-gray-900 tgcss-rounded-full tgcss-ml-2 tgcss-px-2.5 tgcss-pb-1.5 tgcss-pt-1 tgcss-font-semibold tgcss-text-xs tgcss-cursor-pointer">
                        NFT
                    </div>
                    <div class="tgcss-bg-gray-100 hover:tgcss-bg-gray-900 hover:tgcss-text-white tgcss-whitespace-nowrap tgcss-text-gray-900 tgcss-rounded-full tgcss-ml-2 tgcss-px-2.5 tgcss-pb-1.5 tgcss-pt-1 tgcss-font-semibold tgcss-text-xs tgcss-cursor-pointer">
                        توکن صرافی
                    </div>
                </div>

                

                <div class="-tgcss-mx-5 tgcss-overflow-x-auto">
                    <table class="tgcss-w-full tgcss-whitespace-nowrap !tgcss-border-none !tgcss-bg-transparent">
                        <thead class="ltr:tgcss-text-left rtl:tgcss-text-right">
                            <tr>
                                <th class="tgcss-px-4 tgcss-py-3 tgcss-pr-6 tgcss-font-semibold tgcss-border-b tgcss-border-slate-200 ">بازار</th>
                                <th class="tgcss-px-4 tgcss-py-3 first:tgcss-pl-5 last:tgcss-pr-5 tgcss-font-semibold tgcss-border-b tgcss-border-slate-200 ">آخرین قیمت</th>
                                <th class="tgcss-px-4 tgcss-py-3 first:tgcss-pl-5 last:tgcss-pr-5 tgcss-font-semibold tgcss-border-b tgcss-border-slate-200 tgcss-hidden sm:tgcss-table-cell">تغییرات (24h) </th>
                                <th class="tgcss-px-4 tgcss-py-3 first:tgcss-pl-5 last:tgcss-pr-5 tgcss-font-semibold tgcss-border-b tgcss-border-slate-200 tgcss-hidden md:tgcss-table-cell">حجم معاملات (24h)</th>
                                <th class="tgcss-px-4 tgcss-py-3 tgcss-pl-[20px]  tgcss-font-semibold tgcss-border-b tgcss-border-slate-200 text-center tgcss-hidden md:tgcss-table-cell">عملیات</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in filteredItems" v-bind:key="index" class="hover:tgcss-bg-slate-100 tgcss-group">
                                <td class="tgcss-px-3.5 tgcss-py-3 tgcss-pr-[18px] tgcss-border-y tgcss-border-gray-200 ">
                                    <div class="tgcss-flex tgcss-gap-2 tgcss-items-center">
                                        <svg class="tgcss-w-6 tgcss-ml-2 tgcss-opacity-50 hover:tgcss-opacity-100 tgcss-cursor-pointer" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z" stroke-linecap="round" stroke-linejoin="round"></path>
                                        </svg>
                                        <div class="tgcss-bg-green-100 tgcss-rounded-full tgcss-size-7 tgcss-shrink-0">
                                            <img :src="'https://api.wallex.ir/coins/'+item.symbol+'/icon/svg'" alt="" class="tgcss-h-7 tgcss-rounded-full">
                                        </div>
                                        <a href="#" class="grow !tgcss-no-underline">
                                            <h3 class="!tgcss-font-bold !tgcss-m-0 !tgcss-text-base tgcss-flex tgcss-items-center !tgcss-text-gray-700">{{item.nameFa}}<span class="tgcss-hidden md:tgcss-inline-block tgcss-opacity-50">/{{item.pTypeFa}}</span></h3>
                                            <div class="tgcss-text-gray-500 tgcss-font-medium tgcss-text-xss tgcss-mb-1">{{item.symbol}}<span class="tgcss-opacity-50 tgcss-hidden md:tgcss-inline-block">/{{item.pType}}</span></div>
                                        </a>
                                    </div>
                                </td>
                                <td class="tgcss-px-3.5 tgcss-py-2.5 first:tgcss-pl-5 last:tgcss-pr-5 tgcss-text-sm tgcss-font-semibold tgcss-text-gray-700">
                                    <div class="">{{item.pt}}</div>
                                    <div class="tgcss-font-medium tgcss-text-xs tgcss-opacity-50">{{item.p}}</div>
                                </td>
                                <td class="tgcss-px-3.5 tgcss-py-2.5 first:tgcss-pl-5 last:tgcss-pr-5 tgcss-text-sm tgcss-font-semibold tgcss-text-gray-700 tgcss-hidden sm:tgcss-table-cell">
                                    <div class="">2,253,366</div>
                                    <div class="tgcss-inline-flex tgcss-items-center  tgcss-font-semibold text-right" :class="item.changeLevel == 'high' ? 'tgcss-text-green-500' : 'tgcss-text-red-500'"  dir="ltr">
                                        <svg class="tgcss-w-4 tgcss-mr-1" :class="item.changeLevel == 'low' ? ' tgcss-rotate-180 ' : ''"  data-slot="icon" aria-hidden="true" fill="none" stroke-width="3" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="m4.5 15.75 7.5-7.5 7.5 7.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                        </svg>
                                        {{item.change}}
                                    </div>   
                                </td>
                                <td class="tgcss-px-3.5 tgcss-py-2.5 first:tgcss-pl-5 last:tgcss-pr-5 tgcss-text-sm tgcss-font-semibold tgcss-text-gray-700 tgcss-hidden md:tgcss-table-cell">
                                    <div class="">{{item.changeTotalFa}}</div>
                                    <div class="tgcss-font-medium tgcss-text-xs tgcss-opacity-50">{{item.changeTotal}}</div>
                                </td>
                                <td class="tgcss-px-3.5 tgcss-py-2.5 tgcss-pl-[20px] tgcss-text-sm tgcss-text-gray-700 tgcss-font-semibold tgcss-max-w-20 text-left tgcss-hidden md:tgcss-table-cell">
                                    <button class="tgcss-bg-[#f4f5f7] hover:tgcss-bg-[#f8931d] group group-hover:tgcss-bg-[#f8931d] group-hover:tgcss-text-white tgcss-text-gray-700 hover:tgcss-text-white tgcss-font-bold tgcss-pt-2 tgcss-pb-3 tgcss-px-4 !tgcss-rounded-md tgcss-w-full"> معامله </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            
        </div>

    </div>
</template>


<script>

export default {
    name: 'TradeMarkets',
    components: {},
    data: function () {
        return {
            search:'',
            marketBaseShow:false,
            marketBaseSelect:[
                {
                    "id" : 1,
                    "name" : "همه",
                    "type" : "all",
                }
            ],
            marketBaseList:[
                {
                    "id" : 1,
                    "name" : "همه",
                    "type" : "all",
                },
                {
                    "id" : 2,
                    "name" : "تومان",
                    "type" : "TMN",
                },
                {
                    "id" : 3,
                    "name" : "تتر",
                    "type" : "USDT",
                },
                {
                    "id" : 4,
                    "name" : "بیت‌کوین",
                    "type" : "BTC",
                }
            ],
            sortShow:false,
            sortSelect:[
                {
                    "id" : 1,
                    "name" : "جدیدترین‌ها",
                    "type" : "new",
                }
            ],
            sortList:[
                {
                    "id" : 1,
                    "name" : "جدیدترین‌ها",
                    "type" : "new",
                },
                {
                    "id" : 2,
                    "name" : "داغ‌ترین‌ها",
                    "type" : "HOT",
                },
                {
                    "id" : 3,
                    "name" : "بیشترین‌سود",
                    "type" : "high",
                },
                {
                    "id" : 4,
                    "name" : "بیشترین‌ضرر",
                    "type" : "low",
                }
            ],
            List:[
                {
                    "id":"1",
                    "name":"Bitcoin",
                    "nameFa":"ریال",
                    "symbol":"Rial",
                    "pType":"Rial",
                    "pTypeFa":"ریال",
                    "deposit": "450,000",
                    "p":"$68,495",
                    "pt":"4,435,000,001",
                    "changep":"2,253,366",
                    "change":"+3.16٪",
                    "changeLevel":"high",
                    "changeTotal":"32,500",
                    "changeTotalFa":"450,000",
                    "actions":{
                        "withdraw":true,
                        "deposit":true,
                        "trade":false,
                    }
                },
                {
                    "id":"1",
                    "name":"Bitcoin",
                    "nameFa":"بیت‌کوین",
                    "symbol":"BTC",
                    "pType":"TMN",
                    "pTypeFa":"تومان",
                    "deposit": "0.12",
                    "p":"$68,495",
                    "pt":"4,435,000,001",
                    "changep":"2,253,366",
                    "change":"+3.16٪",
                    "changeLevel":"high",
                    "changeTotal":"20,794,000",
                    "changeTotalFa":"90,017,302",
                    "actions":{
                        "withdraw":true,
                        "deposit":true,
                        "trade":true,
                    }
                },
                {
                    "id":"2",
                    "name":"Ethereum",
                    "nameFa":"اتریوم",
                    "symbol":"ETH",
                    "pType":"TMN",
                    "pTypeFa":"تومان",
                    "deposit": "2.7",
                    "p":"$3,495",
                    "pt":"435,000,001",
                    "changep":"253,366",
                    "change":"-2.16٪",
                    "changeLevel":"low",
                    "changeTotal":"2,420,000",
                    "changeTotalFa":"12,302,475",
                    "actions":{
                        "withdraw":true,
                        "deposit":true,
                        "trade":true,
                    }
                },
                {
                    "id":"3",
                    "name":"Tether",
                    "nameFa":"تتر",
                    "symbol":"USDT",
                    "pType":"TMN",
                    "pTypeFa":"تومان",
                    "deposit": "415",
                    "p":"$1",
                    "pt":"68,501",
                    "changep":"1,366",
                    "change":"-3.16٪",
                    "changeLevel":"high",
                    "changeTotal":"94,372",
                    "changeTotalFa":"7,302,475",
                    "actions":{
                        "withdraw":true,
                        "deposit":true,
                        "trade":true,
                    }
                },
                {
                    "id":"4",
                    "name":"Arbitrum",
                    "nameFa":"آربیتروم",
                    "symbol":"ARB",
                    "pType":"TMN",
                    "pTypeFa":"تومان",
                    "deposit": "170",
                    "p":"$1",
                    "pt":"0.11",
                    "changep":"0,06",
                    "change":"-3.16٪",
                    "changeLevel":"high",
                    "changeTotal":"4,372",
                    "changeTotalFa":"302,475",
                    "actions":{
                        "withdraw":true,
                        "deposit":true,
                        "trade":true,
                    }
                }
                
                
            ],
            topItems:[
                {
                    "id":"1",
                    "tag":"بیشترین‌سود",
                    "name":"Bitcoin",
                    "nameFa":"بیت‌کوین",
                    "symbol":"BTC",
                    "pType":"TMN",
                    "pTypeFa":"تومان",
                    "p":"$68,495",
                    "pt":"4,435,000,001",
                    "changep":"504.3",
                    "change":"+3.16٪",
                    "changeLevel":"high",
                    "changeTotal":"$2,794,372",
                    "changeTotalFa":"190,017,302,475",
                },
                {
                    "id":"2",
                    "tag":"بیشترین‌ضرر",
                    "name":"Ethereum",
                    "nameFa":"اتریوم",
                    "symbol":"ETH",
                    "pType":"TMN",
                    "pTypeFa":"تومان",
                    "p":"$3,495",
                    "pt":"435,000,001",
                    "changep":"142.54",
                    "change":"-2.16٪",
                    "changeLevel":"low",
                    "changeTotal":"$794,372",
                    "changeTotalFa":"19,017,302,475",
                },
                {
                    "id":"3",
                    "tag":"جدیدترین",
                    "name":"Tether",
                    "nameFa":"تتر",
                    "symbol":"USDT",
                    "pType":"TMN",
                    "pTypeFa":"تومان",
                    "p":"$1",
                    "pt":"68,501",
                    "changep":"0.0012",
                    "change":"-3.16٪",
                    "changeLevel":"high",
                    "changeTotal":"$794,372",
                    "changeTotalFa":"19,017,302,475",
                },
                {
                    "id":"4",
                    "tag":"داغ‌ترین",
                    "name":"Arbitrum",
                    "nameFa":"آربیتروم",
                    "symbol":"ARB",
                    "pType":"TMN",
                    "pTypeFa":"تومان",
                    "p":"$1",
                    "pt":"0.11",
                    "changep":"0.021",
                    "change":"-3.16٪",
                    "changeLevel":"high",
                    "changeTotal":"$94,372",
                    "changeTotalFa":"19,017,302,475",
                }
                
            ]
        }
    },
    mounted() {
    },
    computed: {
        filteredItems() {
            return this.List.filter(item => {
                return item.nameFa.toLowerCase().includes(this.search.toLowerCase()) || 
                item.name.toLowerCase().includes(this.search.toLowerCase()) || 
                item.pType.toLowerCase().includes(this.search.toLowerCase()) || 
                item.symbol.toLowerCase().includes(this.search.toLowerCase()) 
            })
        }
     },
    methods: {
        selectMarketBase(item){
            this.marketBaseSelect = [item];
        },
        selectSort(item){
            this.sortSelect = [item];
        },
       
    }
}

</script>
    